@import '../common/mixin.scss';

body,
html {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, u {
  text-decoration: none;
}

footer {
  margin-top: 0 !important;
}
.ReactModal__Content--after-open {
  transition: none !important;
}

@mixin bodyBg {
  background-color: #eceff3;
}

#mph {
  --focus: '#122ddf solid 1px';
  --mobile-height: 54px;
  --desktop-height: 60px;
}

@mixin hide {
  display: none;
  opacity: 0;
}

@mixin visible {
  display: block;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

@mixin button {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font-family: Madefor;
}

@mixin linkHover {
  background: #E7F0FF;
  color: #116DFF !important;
}

@mixin linkTextHover {
  color: #116DFF;
}

@mixin textDark {
  color: #000624;
}

@mixin textLightSecondary {
  color: #868aa5;
}

@mixin textMedium {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}

@mixin textRegular {
  font-weight: 400;
}

@mixin textNormal {
  font-weight: 500;
}

@mixin textBold {
  font-weight: 700;
}

@mixin textSmall {
  font-size: 14px;
  line-height: 18px;
}

@mixin link {
  color: #000624;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

@mixin categoryLink {
  color: #868aa5;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}
